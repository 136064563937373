import { User } from 'firebase';

let baseUrl: string;
if (process.env.GATSBY_ACTIVE_ENV === 'production') {
  baseUrl = 'https://us-central1-tas-courier-app.cloudfunctions.net/api';
} else {
  baseUrl = 'https://us-central1-tas-courier-app-staging.cloudfunctions.net/api';
}
export type RequestUserType = {
  uid: string;
  displayName: string;
  email: string;
  password: string;
  role: string;
};

export type ResponseUserType = {
  uid: string;
  email: string;
  role: string;
  displayName: string;
  lastSignInTime: string;
  creationTime: string;
};

export type GetAllUsersResponseType = {
  users: Array<ResponseUserType>;
};

export function responseUserToRequestUser(responseUser: ResponseUserType): RequestUserType {
  return {
    uid: responseUser.uid,
    displayName: responseUser.displayName,
    email: responseUser.email,
    role: responseUser.role,
    password: '',
  };
}

export function getAllUsers(user: User): Promise<GetAllUsersResponseType> {
  return user
    .getIdToken()
    .then(token => {
      return fetch(`${baseUrl}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    })
    .then(response => {
      return response.json();
    });
}

export function addOrUpdateUser(user: User, values: RequestUserType): Promise<void> {
  return user
    .getIdToken()
    .then(token => {
      return fetch(`${baseUrl}/users${values.uid ? `/${values.uid}` : ''}`, {
        method: values.uid ? 'PATCH' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      });
    })
    .then(response => {
      if (response.ok) {
        return Promise.resolve();
      }

      return response.json().then(jsonResponse => {
        if (jsonResponse.message) {
          throw new Error(jsonResponse.message);
        }

        throw new Error(`Could not ${values.uid ? 'create' : 'update'} user.`);
      });
    });
}

export function deleteUser(user: User, uid: string): Promise<void> {
  return user
    .getIdToken()
    .then(token => {
      return fetch(`${baseUrl}/users/${uid}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    })
    .then(response => {
      if (response.ok) {
        return Promise.resolve();
      }

      return response.json().then(jsonResponse => {
        if (jsonResponse.message) {
          throw new Error(jsonResponse.message);
        }

        throw new Error(`Could not delete user.`);
      });
    });
}
