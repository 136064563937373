import firebase from 'firebase/app';
import { firestore } from './firebaseConf';

export type Car = {
  uid: string;
  licencePlate: string;
  distance?: number;
};

export const createOrUpdateCar = (car: Car) => {
  if (car.uid) {
    return firestore
      .collection('cars')
      .doc(car.uid)
      .update({
        licencePlate: car.licencePlate,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }
  return firestore
    .collection('cars')
    .doc()
    .set({
      licencePlate: car.licencePlate,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const getCarList = (callback: Function) => {
  return firestore
    .collection('cars')
    .orderBy('licencePlate')
    .onSnapshot(querySnapshot => {
      const newCarList = querySnapshot.docs.map(doc => {
        return {
          uid: doc.id,
          licencePlate: `${doc.get('licencePlate')}`,
          distance: doc.get('distance'),
        };
      });
      callback(newCarList);
    });
};
