import React, { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { getAllUsers, ResponseUserType } from '../firebaseUsers';
import { useAuthState } from '../state/AuthContext';
import { Car, getCarList } from '../firebaseCars';

interface RegistrationsFiltersProps {
  checkedCars: string[];
  setCheckedCars: Dispatch<SetStateAction<string[]>>;
  checkedUsers: string[];
  setCheckedUser: Dispatch<SetStateAction<string[]>>;
}

const RegistrationsFilters: FunctionComponent<RegistrationsFiltersProps> = ({
  checkedCars,
  setCheckedCars,
  checkedUsers,
  setCheckedUser,
}) => {
  const { user } = useAuthState();

  const [showCarFilter, setShowCarFilter] = useState<boolean>(false);
  const [carList, setCarList] = useState<Car[]>([]);

  const [showUserFilter, setShowUserFilter] = useState<boolean>(false);
  const [userList, setUserList] = useState<ResponseUserType[]>([]);

  useEffect(() => {
    return getCarList(setCarList);
  }, []);

  useEffect(() => {
    if (user != null) {
      getAllUsers(user).then(response => {
        setUserList(response.users);
      });
    }
  }, [user]);

  const checkCar = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (checkedCars.indexOf(event.target.value) === -1) {
        setCheckedCars([...checkedCars, event.target.value]);
      }
    } else {
      if (checkedCars.indexOf(event.target.value) !== -1) {
        setCheckedCars(checkedCars.filter(val => val !== event.target.value));
      }
    }
  };

  const checkUser = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (checkedUsers.indexOf(event.target.value) === -1) {
        setCheckedUser([...checkedUsers, event.target.value]);
      }
    } else {
      if (checkedUsers.indexOf(event.target.value) !== -1) {
        setCheckedUser(checkedUsers.filter(val => val !== event.target.value));
      }
    }
  };

  return (
    <div className="card has-background-light">
      <div className="card-content">
        <h2 className="card-title subtitle">Filter op:</h2>
        <div className="filter filter-car">
          <div className="filter-header">
            <span>Voertuig{checkedCars.length ? ` (${checkedCars.length})` : ''}:</span>
            <button
              type="button"
              className="button is-primary is-small is-pulled-right"
              onClick={() => setShowCarFilter(!showCarFilter)}
            >
              {showCarFilter ? 'Verberg' : 'Toon'}
            </button>
          </div>
          <div className="filter-option-container">
            {showCarFilter &&
              carList.map(car => {
                return (
                  <div className="filter-option-item" key={car.uid}>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        value={car.uid}
                        onChange={checkCar}
                        checked={checkedCars.indexOf(car.uid) !== -1}
                      />
                      <span>{car.licencePlate}</span>
                    </label>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="filter filter-user">
          <div className="filter-header">
            <span>Koerier{checkedUsers.length ? ` (${checkedUsers.length})` : ''}:</span>
            <button
              type="button"
              className="button is-primary is-small is-pulled-right"
              onClick={() => setShowUserFilter(!showUserFilter)}
            >
              {showUserFilter ? 'Verberg' : 'Toon'}
            </button>
          </div>
          <div className="filter-option-container">
            {showUserFilter &&
              userList.map(user => {
                return (
                  <div className="filter-option-item" key={user.uid}>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        value={user.uid}
                        onChange={checkUser}
                        checked={checkedUsers.indexOf(user.uid) !== -1}
                      />
                      <span>{user.displayName}</span>
                    </label>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationsFilters;
