import React, { FunctionComponent, useEffect, useState } from 'react';
import AntdIcon from '@ant-design/icons-react';
import { CaretUpFill, CaretDownFill } from '@ant-design/icons/lib';
import AdminLayout from '../layouts/admin-index';
import {
  ActionRegistrationField,
  ActionsOrder,
  ActionsRegistrationResponse,
  getActionsRegistrations,
  Order,
  printActions,
} from '../firebaseRegistration';
import RegistrationsFilters from '../components/RegistrationsFilters';
import { CSVLink } from 'react-csv';

const Registrations: FunctionComponent = () => {
  const [actionsRegistrations, setActionsRegistrations] = useState<ActionsRegistrationResponse[]>([]);
  const [checkedCars, setCheckedCars] = useState<string[]>([]);
  const [checkedUsers, setCheckedUsers] = useState<string[]>([]);
  const [orderSettings, setOrderSettings] = useState<ActionsOrder>({
    col: 'date',
    order: 'desc',
  });

  const toggleOrderSettings = (col: ActionRegistrationField) => {
    let order: Order = 'asc';

    if (orderSettings.col === col) {
      switch (orderSettings.order) {
        case 'none':
          order = 'asc';
          break;
        case 'asc':
          order = 'desc';
          break;
        default:
          order = 'none';
      }
    }

    setOrderSettings({
      col,
      order,
    });
  };

  useEffect(() => {
    return getActionsRegistrations(orderSettings, (actionsRegistrationList: ActionsRegistrationResponse[]) => {
      setActionsRegistrations(actionsRegistrationList);
    });
  }, [orderSettings, checkedCars, checkedUsers]);

  const csvHeaders = [
    { label: 'Voertuig ID', key: 'carUid' },
    { label: 'Nummerplaat', key: 'carLicensePlate' },
    { label: 'Gebruikers ID', key: 'userUid' },
    { label: 'Gebruikersnaam', key: 'userName' },
    { label: 'Gebruikers e-mailadres', key: 'userEmail' },
    { label: 'Kilometerstand', key: 'distance' },
    { label: 'Wagen gekuisd', key: 'actions.cleanCar' },
    { label: 'Laadruimte gekuisd', key: 'actions.cleanCargoSpace' },
    { label: 'Olie gecontroleerd', key: 'actions.checkOil' },
    { label: 'Olie gecontroleerd', key: 'actions.checkTires' },
    { label: 'Datum', key: 'date' },
    { label: 'Aangemaakt op', key: 'createdAt' },
  ];

  return (
    <AdminLayout>
      <h1 className="title registrations-title">
        <span>Archief</span>
        <CSVLink data={actionsRegistrations} headers={csvHeaders} separator={';'} filename={`archief-export.csv`}>
          <button type="button" className="button is-primary">
            Download CSV
          </button>
        </CSVLink>
      </h1>
      <RegistrationsFilters
        checkedCars={checkedCars}
        setCheckedCars={setCheckedCars}
        checkedUsers={checkedUsers}
        setCheckedUser={setCheckedUsers}
      />
      <div className="card actions-list-container">
        <div className="actions-list__item actions-list__item--header">
          <p
            className="actions-list__item-cell actions-list__item-cell--header actions-list__item-car"
            onClick={() => toggleOrderSettings('carLicensePlate')}
          >
            Voertuig
            {orderSettings.col === 'carLicensePlate' && orderSettings.order === 'asc' && (
              <span className="action-list__item-sorter">
                <AntdIcon type={CaretUpFill} />
              </span>
            )}
            {orderSettings.col === 'carLicensePlate' && orderSettings.order === 'desc' && (
              <span className="action-list__item-sorter">
                <AntdIcon type={CaretDownFill} />
              </span>
            )}
          </p>
          <p
            className="actions-list__item-cell actions-list__item-cell--header actions-list__item-user"
            onClick={() => toggleOrderSettings('userName')}
          >
            Koerier
            {orderSettings.col === 'userName' && orderSettings.order === 'asc' && (
              <span className="action-list__item-sorter">
                <AntdIcon type={CaretUpFill} />
              </span>
            )}
            {orderSettings.col === 'userName' && orderSettings.order === 'desc' && (
              <span className="action-list__item-sorter">
                <AntdIcon type={CaretDownFill} />
              </span>
            )}
          </p>
          <p
            className="actions-list__item-cell actions-list__item-cell--header actions-list__item-date"
            onClick={() => toggleOrderSettings('date')}
          >
            Datum
            {orderSettings.col === 'date' && orderSettings.order === 'asc' && (
              <span className="action-list__item-sorter">
                <AntdIcon type={CaretUpFill} />
              </span>
            )}
            {orderSettings.col === 'date' && orderSettings.order === 'desc' && (
              <span className="action-list__item-sorter">
                <AntdIcon type={CaretDownFill} />
              </span>
            )}
          </p>
          <p
            className="actions-list__item-cell actions-list__item-cell--header actions-list__item-distance"
            onClick={() => toggleOrderSettings('distance')}
          >
            KmStand
            {orderSettings.col === 'distance' && orderSettings.order === 'asc' && (
              <span className="action-list__item-sorter">
                <AntdIcon type={CaretUpFill} />
              </span>
            )}
            {orderSettings.col === 'distance' && orderSettings.order === 'desc' && (
              <span className="action-list__item-sorter">
                <AntdIcon type={CaretDownFill} />
              </span>
            )}
          </p>
          <p className="actions-list__item-cell actions-list__item-actions">Actions</p>
        </div>
        <div className="actions-list">
          {actionsRegistrations.map((actions: ActionsRegistrationResponse) => {
            if (checkedCars.length && !checkedCars.includes(actions.carUid)) {
              return;
            }
            if (checkedUsers.length && !checkedUsers.includes(actions.userUid)) {
              return;
            }
            return (
              <div key={actions.uid} className="actions-list__item">
                <p className="actions-list__item-cell actions-list__item-licence-plate">{actions.carLicensePlate}</p>
                <p className="actions-list__item-cell actions-list__item-user">{actions.userName}</p>
                <p className="actions-list__item-cell actions-list__item-date">
                  {actions.date.toLocaleDateString('nl-BE')}
                </p>
                <p className="actions-list__item-cell actions-list__item-distance">{actions.distance} km</p>
                <p className="actions-list__item-cell actions-list__item-actions">
                  {printActions(actions.actions).map(actionText => (
                    <span key={actionText.replace(' ', '_')}>{actionText}, </span>
                  ))}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Registrations;
