import firebase from 'firebase/app';
import { User } from 'firebase';
import { Car } from './firebaseCars';
import { firestore } from './firebaseConf';

export type ActionRegistrationField =
  | 'carUid'
  | 'carLicensePlate'
  | 'userUid'
  | 'userName'
  | 'userEmail'
  | 'distance'
  | 'actions'
  | 'date'
  | 'createdAt';

export type Action = {
  [cleanCar: string]: boolean;
  cleanCargoSpace: boolean;
  checkOil: boolean;
  checkTires: boolean;
};

export type ActionsRegistration = {
  carUid: string;
  carLicensePlate?: string;
  userUid: string;
  userName?: string;
  userEmail?: string;
  distance: number;
  actions: Action;
  date: Date;
  createdAt: firebase.firestore.FieldValue;
};

export interface ActionsRegistrationResponse extends ActionsRegistration {
  uid: string;
}

export type Order = 'asc' | 'desc' | 'none';

export type ActionsOrder = {
  col: ActionRegistrationField;
  order: Order;
};

const actionTranslations: { [index: string]: string } = {
  cleanCar: 'Wagen gekuisd',
  cleanCargoSpace: 'Laadruimte gekuisd',
  checkOil: 'Olie gecontroleerd',
  checkTires: 'Olie gecontroleerd',
};

export const actionsInit: Action = {
  cleanCar: false,
  cleanCargoSpace: false,
  checkOil: false,
  checkTires: false,
};

export const printActions = (actions: Action) => {
  return Object.keys(actions)
    .filter(actionKey => actions[actionKey])
    .map(actionKey => actionTranslations[actionKey]);
};

const createActionRegistration = (
  car: Car,
  distance: number,
  actions: Action,
  date: Date,
  user: User
): ActionsRegistration => {
  return {
    carUid: car.uid,
    carLicensePlate: car.licencePlate,
    userUid: user.uid,
    userName: user.displayName ? user.displayName : 'not found',
    userEmail: user.email ? user.email : 'not found',
    distance,
    actions,
    date,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
};

export const registerActions = (car: Car, distance: number, actions: Action, date: Date, user: User) => {
  const newActionRegistration = createActionRegistration(car, distance, actions, date, user);
  return firestore.collection('actions').add(newActionRegistration);
};

export const getActionsRegistrations = (orderBy: ActionsOrder, callback: Function): (() => void) => {
  let collectionRef: firebase.firestore.CollectionReference | firebase.firestore.Query = firestore.collection(
    'actions'
  );

  if (orderBy.order !== 'none') {
    collectionRef = collectionRef.orderBy(orderBy.col, orderBy.order);
  }

  return collectionRef.onSnapshot(querySnapshot => {
    const newActionsRegistrationsList = querySnapshot.docs.map(
      (doc): ActionsRegistrationResponse => {
        return {
          uid: doc.id,
          carUid: `${doc.get('carUid')}`,
          carLicensePlate: doc.get('carLicensePlate'),
          userUid: doc.get('userUid'),
          userName: doc.get('userName'),
          userEmail: doc.get('userEmail'),
          distance: doc.get('distance'),
          actions: doc.get('actions'),
          date: doc.get('date').toDate(),
          createdAt: doc.get('createdAt').toDate(),
        };
      }
    );

    callback(newActionsRegistrationsList);
  });
};
